<template>
  <div>
    <Header />
    <section class="bg-white">
      <div class="container py-4">
        <div class="card border-dark">
          <div class="card-header bg-dark text-white">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 py-2">Top Fractional Nft</h6>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-striped log-table-new mb-0">
                <thead>
                  <tr>
                    <td></td>
                    <td>Name</td>
                    <td class="text-start text-nowrap"></td>
                    <td class="text-start text-nowrap">Token</td>
                    <td class="text-start text-nowrap">Market Cap</td>
                    <td class="text-start text-nowrap">Liquidity</td>
                    <td class="text-start text-nowrap">Price</td>
                    <td class="text-start text-nowrap">Total Supply</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(fraction, index) in nftFraction"
                    :key="index"
                    class="align-middle"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>
                      <div
                        class="
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-3
                        "
                      >
                        <img
                          :src="fraction?.images?.image_url"
                          class="img-responsive rounded-10"
                          width="40px"
                          height="40px"
                          alt=""
                        />
                        <div class="d-flex flex-column justify-content-start">
                          <span
                            class="
                              fw-bold
                              text-decoration-none text-dark text-nowrap
                            "
                          >
                            {{ fraction.name }}
                          </span>
                          <a
                            :href="fraction.links.niftex"
                            class="text-dark text-decoration-none"
                            >{{ fraction.platform.name }}</a
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        :href="fraction.links.niftex"
                        class="
                          btn btn-xs-size
                          bg-success bg-opacity-10
                          text-success
                        "
                        >BUY</a
                      >
                    </td>
                    <td>{{ fraction.symbol }}</td>
                    <td class="text-start">
                      <span class="text-end">
                        ${{ fraction.marketCap | formatNumber }}
                      </span>
                    </td>
                    <td>${{ fraction.liquidityInUSD | formatNumber }}</td>
                    <td>${{ Number(fraction.priceInUSD).toFixed(7) }}</td>
                    <td>{{ fraction.totalSupply | formatNumber }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
export default {
  name: "",
  components: { Header, Footer },
  data() {
    return {
      nftFraction: [],
    };
  },
  mounted() {
    this.getNftFraction();
  },
  created() {
    this.$mixpanel.track("Visit NFT Fractional List ");
  },
  methods: {
    getNftFraction() {
      axios.get("https://shardmarketcap.io/api/data").then((res) => {
        this.nftFraction = res.data;
      });
    },
  },
};
</script>

<style>
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
</style>
